// Submit a search form when the input changes, with a delay to let the person type.
//
// Usage:
// - Add `data: {controller: 'search-form', search_form_target: 'form'` to the search form.
// - Add `data: {action: 'input->search-form#search'}` to the search field.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form']

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 200)
  }
}
