import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['loading']

  markAsLoading() {
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.add('loading')
    }
  }
}
