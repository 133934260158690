import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox']

  toggle(event) {
    const togglerCheckbox = event.currentTarget
    this.checkboxTargets.forEach(checkbox => checkbox.checked = togglerCheckbox.checked)
  }
}
