// Stimulus Sortable, with added options
//
// https://www.stimulus-components.com/docs/stimulus-sortable/

import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static values = {
    resourceName: String,
    paramName: String,
    responseKind: String,
    animation: Number,
    draggable: String,
    handle: String,
    filter: String
  }

  initialize () {
    this.end = this.end.bind(this)
  }

  connect () {
    this.sortable = new Sortable(this.element, {
      ...this.defaultOptions,
      ...this.options
    })
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

  async end (event) {
    if (!event.item.dataset.sortableUpdateUrl) { return }

    const paramName = this.hasParamNameValue ? this.paramNameValue : 'position'
    const param = this.resourceNameValue ? `${this.resourceNameValue}[${paramName}]` : paramName

    const data = new FormData()
    data.append(param, event.newDraggableIndex + 1)

    await patch(event.item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue })
  }

  get options () {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      draggable: this.draggableValue || this.defaultOptions.draggable || undefined,
      handle: this.handleValue || this.defaultOptions.handle || undefined,
      filter: this.filterValue || this.defaultOptions.filter || undefined,
      onEnd: this.end
    }
  }

  get defaultOptions() {
    return {
      responseKind: 'html',
      handle: '.handle'
    }
  }
}
