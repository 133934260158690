// Generic form-related functions

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit() {
    this.element.requestSubmit()
      // `submit` bypasses Turbo, `requestSubmit` triggers it
  }
}
