import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "card"
  ];

  static values = {
    url: String
  };

  async show(event) {
    this.hidden = false
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => { this.showAfterDelay(event) }, 500)
  }

  async showAfterDelay(event) {
    if (this.hidden) return

    const element = this.element

    let content = await this.fetch()
    if (!content) return

    if (this.hasCardTarget) {
      this.cardTarget.remove()
    }

    const fragment = document.createRange().createContextualFragment(content)
    element.appendChild(fragment)
  }

  hide(event) {
    this.hidden = true
    if (this.hasCardTarget) {
      this.cardTarget.remove()
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove()
    }
  }

  async fetch() {
    if (!this.remoteContent) {
      if (!this.hasUrlValue) {
        console.error('[popover] You need to pass an url to fetch the popover content.')
        return
      }

      const response = await fetch(this.urlValue)
      this.remoteContent = await response.text()
    }

    return this.remoteContent
  }
}
