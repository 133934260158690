// Restrict a checkbox collection choices to a maximum.
//
// When a checkbox state changes:
// - if the amount of checked boxes equals the maximum
//   then the remaining unchecked ones are disabled.
// - if the amount of checked boxes is below the maximum
//   then the remaining unchecked ones are enabled.
//
// Usage:
// - Define a `data-multi-choice-max-value` number.
// - Tag checkbox input elements with `data-multi-choice-target="choice"`.
// - Call `data-action="multi-choice#restrict"` on checkbox change.
//
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'choice'
  ]

  static values = {
    max: Number
  }

  connect() {
    this.restrict()
  }

  restrict() {
    const disabled = this.checkedChoices.length >= this.maxValue
    this.uncheckedChoices.forEach(element => element.disabled = disabled)
  }

  get checkedChoices() {
    return this.choiceTargets.filter(target => target.checked)
  }

  get uncheckedChoices() {
    return this.choiceTargets.filter(target => !target.checked)
  }
}
