import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'new_association',
    'template'
  ]

  static values = {
    placeholder: String
  }

  add_association(event) {
    event.preventDefault()
    let content = this.templateTarget.innerHTML.replace(this.placeholder_regexp, new Date().valueOf())
    this.new_associationTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove_association(event) {
    event.preventDefault()
    let tpl = event.target.closest(".nested-fields")
    tpl.querySelector("input[name*='_destroy']").value = 1
    tpl.style.display = "none" // don’t remove from DOM, hidden delete field needs to be sent
  }

  get placeholder_regexp() {
    const placeholder = this.placeholderValue || 'TEMPLATE_RECORD'
    return new RegExp(placeholder, 'g')
  }
}
