import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'form',
    'answers',
    'choice',
    'showFormButton',
    'submitButton',
    'vote'
  ]

  connect() {
    this.changeAnswer()
  }

  showForm(event) {
    this.answersTarget.style.display = 'none'
    this.showFormButtonTarget.style.display = 'none'
    this.formTarget.style.display = 'block'
  }

  changeAnswer(event) {
    if (!this.hasSubmitButtonTarget) { return }

    const hasCheckedChoices = this.choiceTargets.some(target => target.checked)

    if (hasCheckedChoices) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  submit(event) {
    this.submitButtonTarget.classList.add('loading')
  }
}
