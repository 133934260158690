import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'nav']

  connect() {
    this.mediaQueryList = window.matchMedia('(min-width: 1024px)')
    this.mediaQueryList.addEventListener('change', (e) => { this.onWidthChange(e) })
    this.onWidthChange(this.mediaQueryList)
  }

  onWidthChange(event) {
    if (event.matches) {
      this.toggleNav(true) // nav always shown for desktop
    } else {
      this.toggleNav(false) // nav hidden by default for mobile
    }
  }

  onToggle(event) {
    this.toggleNav(this.isNavDisplayed ? false : true)
  }

  toggleNav(showNav) {
    if (showNav) {
      this.buttonTarget.setAttribute('aria-expanded', 'true')
      this.navTarget.style.display = 'block'
    } else {
      this.buttonTarget.removeAttribute('aria-expanded')
      this.navTarget.style.display = 'none'
    }
  }

  get isNavDisplayed() {
    return this.navTarget.style.display != 'none'
  }
}
