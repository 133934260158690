// Reload a turbo-frame in response to a broadcast.
//
// Usage:
//   <turbo-frame
//     id="participants"
//     data-controller="frame"
//     data-frame-scope-value="meeting_1_participants"
//     data-frame-src-value="/en/meetings/1/participants/list"
//     >
//
//   FrameChannel.broadcast_to 'meeting_1_participants', target: 'participants'

import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = {
    action: String,
    scope: String,
    src: String
  }

  connect() {
    const frameController = this

    this.subscription = consumer.subscriptions.create(
      {channel: "FrameChannel", scope: this.scopeValue}, {

      received(data) {
        frameController.fetchList(data.target)
      },

      disconnected() {
        this.unsubscribe()
      }
    })
  }

  fetchList(target) {
    if (target) {
      let targetElement = document.getElementById(target)
      if (targetElement && this.hasSrcValue) {
        fetch(this.srcValue)
          .then(response => {
            if (!response.ok) { throw new Error(response.status) }
            return response.text()
          })
          .then(html => {
            targetElement.innerHTML = html
          })
          .catch(error => {
            console.warn('Error fetching the frame')
          })
      }
    }
  }
}
