// Show / hide the proxy card depending on the registration option.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'option',
    'ballot',
    'proxyCard',
    'proxyCardInstructions',
    'submitButton'
  ]

  static values = {
    confirmText: String
  }

  connect() {
    const checkedOption = this.optionTargets.filter(target => target.checked)[0]
    if (checkedOption) {
      this.toggleBallotAndProxyCard(null, checkedOption)
    } else {
      this.hideBallot()
      this.hideProxyCard()
    }
  }

  preventSubmitOnEnter(event) {
    if (event.key && event.key == 'Enter') {
      event.preventDefault()
    }
  }

  toggleBallotAndProxyCard(event, target) {
    target ||= event.target

    switch (target.value) {
    case 'beforehand':
      this.showBallot()
      this.hideProxyCard()
      break;
    case 'with_voting_instructions':
      this.hideBallot()
      this.showProxyCard()
      break;
    case 'without_voting_instructions':
      this.hideBallot()
      this.showProxyCard()
      this.hideProxyCardInstructions()
      break;
    case 'present_online':
    case 'present_onsite':
      this.hideBallot()
      this.hideProxyCard()
      break;
    case 'absent':
      this.hideBallot()
      this.hideProxyCard()
      break;
    default:
      console.warn('option value not supported', target.value)
    }
  }

  toggleConfirm(event) {
    switch (event.target.value) {
    case 'beforehand':
    case 'present_online':
    case 'present_onsite':
      delete this.submitButtonTarget.dataset.turboConfirm
      break;
    case 'with_voting_instructions':
    case 'without_voting_instructions':
    case 'absent':
      if (this.confirmTextValue == '') {
        delete this.submitButtonTarget.dataset.turboConfirm
      } else {
        this.submitButtonTarget.dataset.turboConfirm = this.confirmTextValue
      }
      break;
    default:
      console.warn('option value not supported', event.target.value)
    }
  }

  showBallot() {
    if (!this.hasBallotTarget) { return }
    this.ballotTarget.style.display = 'block'
  }

  hideBallot() {
    if (!this.hasBallotTarget) { return }
    this.ballotTarget.style.display = 'none'
  }

  showProxyCard() {
    if (!this.hasProxyCardTarget) { return }
    this.proxyCardTarget.style.display = 'block'

    if (!this.hasProxyCardInstructionsTarget) { return }
    this.proxyCardInstructionsTarget.style.display = 'block'
  }

  hideProxyCard() {
    if (!this.hasProxyCardTarget) { return }
    this.proxyCardTarget.style.display = 'none'
  }

  hideProxyCardInstructions() {
    if (!this.hasProxyCardInstructionsTarget) { return }
    this.proxyCardInstructionsTarget.style.display = 'none'
  }
}
