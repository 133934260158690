// Show or hide the `toggled` target depending on the `toggler` checkbox state when `toggle` is called.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "toggler",
    "toggled"
  ]

  static values = {
    mode: String,
    toggledStyle: String
  }

  connect() {
    if (this.modeValue != 'inherit') {
      this.toggle()
    }
  }

  toggle(event) {
    if (!this.hasTogglerTarget || !this.hasToggledTarget) { return }

    if (this.modeValue == 'inherit') {
      this.toggledTargets.forEach(target => target.style.display = (target.style.display == 'none' ? this.toggledStyle : 'none'))
    } else {
      this.toggledTarget.style.display = this.togglerChecked ? this.toggledStyle : 'none'
    }
  }

  get togglerChecked() {
    if (!this.hasTogglerTarget) { return false }
    return this.togglerTarget.checked
  }

  get toggledStyle() {
    return this.hasToggledStyleValue ? this.toggledStyleValue : 'block'
  }
}
