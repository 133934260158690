// Close `details`-based dropdowns when clicked outside.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.clickListener = document.addEventListener('click', (event) => { this.onDocumentClick(event) })
  }

  disconnect() {
    document.removeEventListener('click', this.clickListener)
  }

  onDocumentClick(event) {
    try {
      const details = event.target.closest('details')

      const allDetails = document.querySelectorAll('details[data-controller=dropdown]')
      allDetails.forEach((d) => { if (d != details) { d.open = false } })
    }
    catch (e) {
       // `closest` not in IE, skip feature in that case
    }
  }
}
