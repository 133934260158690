import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['fileInput', 'submitButton']

  connect() {
    this.change()
  }

  change(event) {
    const hasFiles = this.fileInputTarget.value != ''

    if (hasFiles) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }
}
