// Select2-like behaviour for text field + datalist.
//
// The value of a hidden field marked as a `value` target will be filled
// with the selected `id` from the items returned by the search.

import { Controller } from "@hotwired/stimulus"
import { get }        from '@rails/request.js'

export default class extends Controller {
  static classes = ['error']
  static targets = ['query', 'value', 'list']
  static values = { url: String }

  async search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => { this.searchQuery() }, 500)
    event.preventDefault()
  }

  // private

  async searchQuery() {
    const response = await get(this.urlValue, {query: {q: this.query}, responseKind: 'html'})

    if (response.ok) {
      const list = await response.html
      this.listTarget.innerHTML = list
      this.setValueAndClass()
    } else {
      this.setValueAndClass()
    }
  }

  setValueAndClass(event) {
    const option = this.selectedDatalistOption

    if (option != null) {
      this.value = option.dataset.value

      if (this.hasErrorClass) {
        this.queryTarget.classList.remove(this.errorClass)
      }
    } else {
      this.value = ''

      if (this.hasErrorClass) {
        this.queryTarget.classList.add(this.errorClass)
      }
    }
  }

  get query() {
    return this.queryTarget.value.toLowerCase().trim()
  }

  get datalist() {
    return this.queryTarget.list
  }

  set value(value) {
    if (!this.hasValueTarget) { return }
    this.valueTarget.value = value
  }

  get selectedDatalistOption() {
    const query = this.query
    for (let option of this.datalist.children) {
      const name = option.innerText.toLowerCase().trim()
      if (name == query) { return option }
    }
    return null
  }
}
