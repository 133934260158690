// Show or close a <dialog> modal.
//
// Native support on Chrome, Edge, Opera, and Android.
// Polyfill for other browsers.
// Last-resort fallback if polyfill fails: the dialog is displayed in the normal HTML flow.

import { Controller } from "@hotwired/stimulus"
import dialogPolyfill from "dialog-polyfill"

export default class extends Controller {
  static classes = ['active']
  static targets = ['dialog']

  connect() {
    dialogPolyfill.registerDialog(this.dialogTarget)
  }

  show(event) {
    if (this.hasActiveClass) { this.dialogTarget.classList.add(this.activeClass) }
    if (typeof this.dialogTarget.showModal !== 'function') { return this.showFallback(event) }
    this.dialogTarget.showModal()
  }

  close(event) {
    if (event.key && event.key !== 'Escape') { return }
    if (this.hasActiveClass) { this.dialogTarget.classList.remove(this.activeClass) }
    if (typeof this.dialogTarget.close !== 'function') { return this.closeFallback(event) }
    this.dialogTarget.close()
  }

  showFallback(event) {
    this.dialogTarget.style.display = 'block'
    this.dialogTarget.style.position = 'static'
    this.dialogTarget.style.width = '100%'
  }

  closeFallback(event) {
    this.dialogTarget.style.display = 'none'
  }
}
