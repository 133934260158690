// Shows the fieldToToggle if the checked radio input
// matches one of the activeValues (CSV). Hides if not.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "fieldToToggle"
  ]

  static values = {
    activeValues: String
  }

  toggleField(event) {
    if (!this.hasFieldToToggleTarget) { return }

    const activeValues = this.activeValuesValue.split(",")
    const checkedValue = event.target.value

    for (let activeValue of activeValues) {
      if (checkedValue == activeValue) {
        this.fieldToToggleTargets.forEach(target => target.style.display = "block")
        break
      } else {
        this.fieldToToggleTargets.forEach(target => target.style.display = "none")
      }
    }
  }
}
