// Shows the fieldToToggle if the selected option value
// matches one of the activeValues (CSV). Hides if not.
// It resets the value of the fieldToReset to zero.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "fieldToToggle",
    "fieldToSet",
    "fieldToReset"
  ]

  static values = {
    activeValues: String,
    set: String,
    reset: String
  }

  toggleField(event) {
    // Per-controller active values
    if (this.hasActiveValuesValue) {
      const activeValues = this.activeValuesValue.split(",")
      const selectedValue = event.target.options[event.target.selectedIndex].value

      for (let activeValue of activeValues) {
        if (selectedValue == activeValue) {
          this.fieldToToggleTargets.forEach(target => target.style.display = "block")
          this.fieldToSetTargets.forEach(target => target.value = this.hasSetValue ? this.setValue : 1)
          break
        } else {
          this.fieldToToggleTargets.forEach(target => target.style.display = "none")
          this.fieldToResetTargets.forEach(target => target.value = this.hasResetValue ? this.resetValue : 0)
        }
      }
    }
    // Per-target active values
    else {
      const selectedValue = event.target.options[event.target.selectedIndex].value

      this.fieldToToggleTargets.forEach(target => {
        const activeValues = target.dataset.activeValues?.split(",") ?? []

        if (activeValues.includes(selectedValue)) {
          target.style.display = "block"
        } else {
          target.style.display = "none"
        }
      })
    }
  }
}
