import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = {ballotId: Number}

  connect() {
    this.subscription = consumer.subscriptions.create(
      {channel: "PingChannel", ballot_id: this.ballotIdValue}, {
      received(data) {
        this.perform("pong", {ballot_id: data.ballot_id, resolution_id: data.resolution_id})
      },
      disconnected() {
        this.unsubscribe()
      }
    })
  }
}
