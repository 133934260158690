// Subscribe to the ballot channel,
// and add/remove the live resolution when a broadcast is received.

import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [
    'resolution',
    'resolutions'
  ]

  static values = {
    meetingId: Number,
    resolutionUrl: String,
    voterId: Number
  }

  connect() {
    const ballotController = this

    this.subscription = consumer.subscriptions.create(
      {channel: "BallotChannel", id: this.meetingIdValue}, {
      received(data) {
        ballotController.update(data)
      },
      disconnected() {
        this.unsubscribe()
      }
    })
  }

  update(data) {
    if (data.resolution) {
      this.updateResolution(data.resolution)
    }
  }

  updateResolution(resolution) {
    switch (resolution.live_state) {
    case 'live_off':
    case 'live_display':
    case 'live_vote':
    case 'live_results':
      this.addResolution(resolution)
      break
    default:
      console.warn('live_state value not supported', resolution.live_state)
    }
  }

  removeResolution(resolution) {
    const element = this.resolutionElement(resolution.id)

    if (element) {
      const parentElement = element.parentNode

      if (parentElement) {
        parentElement.removeChild(element) // remove() is not supported in IE11
      }
    }
  }

  addResolution(resolution) {
    fetch(`${this.resolutionUrlValue}/${resolution.id}` + this.voterIdParam)
      .then(response => {
        if (!response.ok) { throw new Error(response.status) }
        return response.text()
      })
      .then(html => {
        this.addOrReplaceResolutionHTML(resolution, html)
        this.scrollTo(resolution)
      })
      .catch(error => {
        this.addOrReplaceResolutionHTML(resolution, '<section class="ui red segment">Error updating the vote. Please reload this page.</section>')
      })
  }

  addOrReplaceResolutionHTML(resolution, html) {
    const element = this.resolutionElement(resolution.id)
    if (element) {
      element.outerHTML = html
    } else {
      this.resolutionsTarget.innerHTML += html
    }
  }

  scrollTo(resolution) {
    const element = this.resolutionElement(resolution.id)
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  resolutionElement(resolutionId) {
    return this.resolutionTargets.filter(target => target.dataset.resolutionId == resolutionId)[0]
  }

  get voterIdParam() {
    const voterId = this.voterIdValue
    return voterId ? `?voter_id=${voterId}` : ''
  }
}
