// Toggle an element on or off, and optionally disable input fields in that element.
//
// Usage:
//
// ```haml
// %div{data: {controller: 'toggler'}}
//   = f.radio_button :kind, :legal, data: {
//       action: 'toggler#toggle toggler#disableFields',
//       toggler_elements_on_param: '#legal',
//       toggler_elements_off_param: '#natural'
//     }
//   #legal
//   #natural
// ```

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    if (event.params.elementsOn) {
      const elementsOn = event.params.elementsOn.split(',').map(e => this.element.querySelector(e))
      elementsOn.forEach(element => element.hidden = null)
    }

    if (event.params.elementsOff) {
      const elementsOff = event.params.elementsOff.split(',').map(e => this.element.querySelector(e))
      elementsOff.forEach(element => element.hidden = 'hidden')
    }
  }

  disableFields(event) {
    const elementsOn = event.params.elementsOn.split(',').map(e => this.element.querySelector(e))
    const elementsOff = event.params.elementsOff.split(',').map(e => this.element.querySelector(e))

    elementsOn.forEach(element => {
      element.querySelectorAll('input').forEach(input => input.disabled = null)
    })
    elementsOff.forEach(element => {
      element.querySelectorAll('input').forEach(input => input.disabled = 'disabled')
    })

  }
}
